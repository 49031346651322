<template>
  <v-container fluid>
    <div class="d-flex">
      <v-btn class="ma-1" color="primary" @click="getAllData" small>
        <v-icon left>mdi-refresh</v-icon>Recargar
      </v-btn>
      <v-btn class="ma-1" color="primary" to="/impagados/informe" small>
        <v-icon left>mdi-calendar</v-icon>Generar resumen
      </v-btn>
      <!-- <v-btn class="ma-1" color="primary" @click="verCalendario = !verCalendario" small>
        <v-icon left>mdi-calendar-outline</v-icon>Ver calendario
      </v-btn>-->
    </div>

    <div class="d-flex">
      <v-subheader>HOY</v-subheader>
      <v-spacer></v-spacer>
      <v-subheader>{{parseDate(Date.now())}}</v-subheader>
    </div>
    <v-progress-linear class="mb-2 mt-n2" v-if="Object.keys(elementosFiltrados).length == 0" indeterminate></v-progress-linear>
    <v-divider class="mb-2 mt-n2" v-else></v-divider>
    <v-row>
      <v-col
        cols="12"
        md="4"
        lg="3"
        v-for="item in items.filter(item => filter[item.name])"
        :key="item.name"
      >
        <v-card class="pa-3">
          <component :is="item.titulo"></component>
          <v-divider class="mt-2"></v-divider>
          <v-list dense >
            <v-list-group sub-group
              v-for="value in elementosFiltrados[item.name]"
              :prepend-icon="{}.toString.call(item.groupIcon) === '[object Function]' ? item.groupIcon(item) : item.groupIcon"
            >
              <template v-slot:activator>
                <v-list-item-title
                  v-text="[value[item.groupKey], value[item.groupSubKey]].filter(x => !!x).join(' - ')"
                ></v-list-item-title>
                <!-- <v-list-item-title>{{ value[item.groupKey] }} {{ item.groupSubKey ? ` - ${value[item.groupSubKey]}` : '' }}</v-list-item-title> -->
              </template>
              <v-list-item v-for="imp in value.impagados">
                <v-list-item-content>
                  <v-list-item-title>{{ imp.SerieFactura }} {{ imp.NumeroFactura }}</v-list-item-title>
                  <v-list-item-subtitle>{{ imp.ImporteTotal }} €</v-list-item-subtitle>
                </v-list-item-content>
                <span
                  class="text-caption"
                  v-if="item.actionDate"
                >{{ parseDate(imp[item.actionDate]) }}</span>
                <status-chip
                  class="text-caption"
                  v-if="item.actionChip"
                  :value="imp[item.actionChip]"
                />
                <v-list-item-action>
                  <table-button
                    color="error"
                    small
                    :to="`/impagados/control?${item.groupKey}=${value[item.groupKey]}`"
                    icon
                    tooltip="Ver en control"
                  >
                    <v-icon>mdi-currency-eur-off</v-icon>
                  </table-button>
                </v-list-item-action>
              </v-list-item>
            </v-list-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <v-card class="mt-6">
      <v-subheader>
        <v-icon small left>mdi-filter</v-icon>Filtros tareas
      </v-subheader>
      <v-divider></v-divider>
      <div class="d-flex flex-wrap pa-3" style="gap: 10px">
        <v-chip
          dense
          hide-details
          v-model="filter[item.name]"
          v-for="item in items"
          :key="item.name"
          @click.stop="$set(filter, item.name, !filter[item.name])"
          :class="{ 'v-chip--active': filter[item.name] }"
          outlined
          :color="item.chipColor"
        >
          <span>{{ item.label }}</span>
        </v-chip>
        <!-- <v-checkbox
          dense
          hide-details
          v-model="filter[item.name]"
          v-for="item in items"
          :key="item.name"
        >
        <template v-slot:label>
          <span>{{item.label}}</span>
          <v-chip small label class="ml-1">{{Object.values(elementosFiltrados[item.name]).length}}</v-chip>
        </template>
        </v-checkbox>-->
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { parseDate, timeAgo, downloadFile, getSituacionIcon } from '@/utils/index.js';
import Vue from 'vue'
import StatusChip from '@/components/StatusChip.vue';
import { VIcon, VSubheader, VSpacer } from 'vuetify/lib'
import { parseImpagados } from '../services/parseImpagados';

export default {
  components: {
    StatusChip, VIcon, VSubheader, VSpacer,
    TarjetaDatos: () => import('@/components/TarjetaDatos.vue'),
    TableFilters: () => import('@/components/TableFilters.vue'),
    TableButton: () => import('@/components/TableButton.vue'),
  },
  data() {
    return {
      loading: false,
      impagados: [],
      facturas: [],

      calendarioDate: new Date().getTime(),

      detailsId: null,
      verCalendario: false,
      pdfId: null,
      tarea: 'fraccionamientos',
      tab: null,

      filter: localStorage.hasOwnProperty('filterTareas') ? JSON.parse(localStorage.getItem('filterTareas')) : {},

      items: [
        // {
        //   name: 'llamar',
        //   label: 'Hacer llamadas',
        //   chipColor: getSituacionIcon('Llamada').color,
        //   titulo: Vue.component('titulo-llamar', {
        //     render: function (createElement) {
        //       return createElement('div',
        //         { class: 'd-flex align-center' },
        //         [
        //           createElement(StatusChip, { props: { value: "Llamada" } }),
        //           createElement(VIcon, { props: { small: true } }, 'mdi-minus'),
        //           createElement(StatusChip, { props: { value: "Email" } }),
        //           createElement(VSpacer),
        //           createElement(VSubheader, 'Sin mensajes en 5 días'),
        //         ])
        //     }
        //   }),
        //   groupKey: 'denominacion',
        //   groupIcon: 'mdi-account',
        //   actionDate: 'ultimoMensajeFecha'
        // },
        {
          name: 'primerAviso',
          label: 'Enviar 1ª carta',
          chipColor: getSituacionIcon('1ª Carta').color,
          titulo: Vue.component('titulo-primer-aviso', {
            render: function (createElement) {
              return createElement('div',
                { class: 'd-flex align-center' },
                [
                  createElement(StatusChip, { props: { value: "1ª Carta" } }),
                  createElement(VSpacer),
                  createElement(VSubheader, 'Enviar primera Carta'),
                ])
            }
          }),
          groupKey: 'CodigoContrato',
          groupSubKey: 'denominacion',
          groupIcon: 'mdi-file-document-edit-outline',
          actionDate: 'envioCarta1'
        },
        {
          name: 'segundoAviso',
          label: 'Enviar 2ª carta',
          chipColor: getSituacionIcon('2ª Carta').color,
          titulo: Vue.component('titulo-segundo-aviso', {
            render: function (createElement) {
              return createElement('div',
                { class: 'd-flex align-center' },
                [
                  createElement(StatusChip, { props: { value: "2ª Carta" } }),
                  createElement(VSpacer),
                  createElement(VSubheader, 'Enviar segunda Carta'),
                ])
            }
          }),
          groupKey: 'CodigoContrato',
          groupSubKey: 'denominacion',
          groupIcon: 'mdi-file-document-edit-outline',
          actionDate: 'envioCarta2'
        },
        {
          name: 'corteAviso',
          label: 'Enviar carta de corte',
          chipColor: getSituacionIcon('Carta de Corte').color,
          titulo: Vue.component('titulo-carta-corte', {
            render: function (createElement) {
              return createElement('div',
                { class: 'd-flex align-center' },
                [
                  createElement(StatusChip, { props: { value: "Carta de Corte" } }),
                  createElement(VSpacer),
                  createElement(VSubheader, 'Enviar Carta de Corte'),
                ])
            }
          }),
          groupKey: 'CodigoContrato',
          groupSubKey: 'denominacion',
          groupIcon: 'mdi-file-document-edit-outline',
          actionDate: 'envioCarta3'
        },
        {
          name: 'comprobarPago',
          label: 'Comprobar la transferencia',
          chipColor: getSituacionIcon('Comprometido Pago').color,
          titulo: Vue.component('titulo-comprobar-pago', {
            render: function (createElement) {
              return createElement('div',
                { class: 'd-flex align-center' },
                [
                  createElement(StatusChip, { props: { value: "Comprometido Pago" } }),
                  createElement(VSpacer),
                  createElement(VSubheader, 'Comprobar transferencia'),
                ])
            }
          }),
          groupKey: 'denominacion',
          groupIcon: 'mdi-account-cash-outline',
          actionDate: 'estadoPropioVencimiento'
        },
        {
          name: 'impagadosAbonados',
          label: 'Dar por pagados',
          chipColor: getSituacionIcon('Abonado').color,
          titulo: Vue.component('titulo-abonados', {
            render: function (createElement) {
              return createElement('div',
                { class: 'd-flex align-center' },
                [
                  createElement(StatusChip, { props: { value: "Abonado" } }),
                  createElement(VIcon, { props: { small: true } }, 'mdi-minus'),
                  createElement(StatusChip, { props: { value: "Pagado Tarjeta" } }),
                  createElement(VSpacer),
                  createElement(VSubheader, 'Dar por pagado'),
                ])
            }
          }),
          groupKey: 'CodigoContrato',
          groupSubKey: 'denominacion',
          groupIcon: (imp) => imp.estadoPropio == 'Abonado' ? 'mdi-cash' : 'mdi-credit-card-outline',
          actionDate: 'estadoPropioFecha'
        },
        {
          name: 'pendienteGiro',
          label: 'Girar al banco',
          chipColor: getSituacionIcon('Pendiente Giro').color,
          titulo: Vue.component('titulo-giro', {
            render: function (createElement) {
              return createElement('div',
                { class: 'd-flex align-center' },
                [
                  createElement(StatusChip, { props: { value: "Pendiente Giro" } }),
                  createElement(VSpacer),
                  createElement(VSubheader, 'Pendiente de giro'),
                ])
            }
          }),
          groupKey: 'CodigoContrato',
          groupSubKey: 'denominacion',
          groupIcon: 'mdi-bank',
          actionDate: 'estadoPropioFecha'
        },
        {
          name: 'asignarProducto',
          label: 'Asignar producto impagado',
          titulo: Vue.component('titulo-asignar-producto', {
            render: function (createElement) {
              return createElement('div',
                { class: 'd-flex align-center' },
                [
                  // createElement(StatusChip, { props: { value: "Impagado" } }),
                  createElement(VIcon, 'mdi-playlist-plus'),
                  createElement(VSpacer),
                  createElement(VSubheader, 'Asignar producto impagado'),
                ])
            }
          }),
          groupKey: 'CodigoContrato',
          groupIcon: 'mdi-file-document-edit-outline',
          actionDate: 'FechaImpagado'
        },
        {
          name: 'fraccionamientos',
          label: 'Iniciar fraccionamiento',
          chipColor: getSituacionIcon('Fraccionado 3 Pagos').color,
          titulo: Vue.component('titulo-iniciar-fraccionamiento', {
            render: function (createElement) {
              return createElement('div',
                { class: 'd-flex align-center' },
                [
                  // createElement(StatusChip, { props: { value: "Impagado" } }),
                  createElement(VIcon, 'mdi-division'),
                  createElement(VSpacer),
                  createElement(VSubheader, 'Iniciar Fraccionamiento'),
                ])
            }
          }),
          groupKey: 'CodigoContrato',
          groupIcon: 'mdi-file-document-edit-outline',
          actionChip: 'estadoPropio'
        },
        {
          name: 'enviarCorte',
          label: 'Enviar corte a distribuidora',
          chipColor: getSituacionIcon('Cortado').color,
          titulo: Vue.component('titulo-enviar-corte', {
            render: function (createElement) {
              return createElement('div',
                { class: 'd-flex align-center' },
                [
                  createElement(StatusChip, { props: { value: "Cortado" } }),
                  // createElement(VIcon, 'mdi-skull'),
                  createElement(VSpacer),
                  createElement(VSubheader, 'Enviar corte a distribuidora'),
                ])
            }
          }),
          groupKey: 'CodigoContrato',
          groupIcon: 'mdi-file-document-edit-outline',
          actionDate: 'cartaCorte'
        }
      ],
    }
  },

  computed: {
    elementosFiltrados() {
      if (this.impagados.length == 0) return {}

      const fraccionamientos = this.impagados.reduce(function (r, a) {
        if (/Fraccionado \d Pagos/g.test(a.estadoPropio)) {
          r[a.CodigoContrato] = r[a.CodigoContrato] || { CodigoContrato: a.CodigoContrato, denominacion: a.denominacion, IdCliente: a.IdCliente, Identificador: a.Identificador, impagados: [] };
          r[a.CodigoContrato].impagados.push(a);
        }
        return r;
      }, Object.create(null))

      const primerAviso = this.impagados.reduce(function (r, a) {
        if (!Number(a.dptoJuridico) && !['Fraccionado 2 Pagos', 'Fraccionado 3 Pagos', 'Abonado'].includes(a.estadoPropio) && a.envioCarta1 <= Date.now() && !Number(a.carta1) && (a.envioCarta1 != null)) {
          r[a.CodigoContrato] = r[a.CodigoContrato] || { CodigoContrato: a.CodigoContrato, denominacion: a.denominacion, IdCliente: a.IdCliente, Identificador: a.Identificador, impagados: [] };
          r[a.CodigoContrato].impagados.push(a);
        }
        return r;
      }, Object.create(null))

      const segundoAviso = this.impagados.reduce(function (r, a) {
        if (!Number(a.dptoJuridico) && !['Fraccionado 2 Pagos', 'Fraccionado 3 Pagos', 'Abonado'].includes(a.estadoPropio) && a.envioCarta2 <= Date.now() && !Number(a.carta2) && (a.envioCarta2 != null)) {
          r[a.CodigoContrato] = r[a.CodigoContrato] || { CodigoContrato: a.CodigoContrato, denominacion: a.denominacion, IdCliente: a.IdCliente, Identificador: a.Identificador, impagados: [] };
          r[a.CodigoContrato].impagados.push(a);
        }
        return r;
      }, Object.create(null))

      const corteAviso = this.impagados.reduce(function (r, a) {
        if (!Number(a.dptoJuridico) && !['Fraccionado 2 Pagos', 'Fraccionado 3 Pagos', 'Abonado'].includes(a.estadoPropio) && a.envioCarta3 <= Date.now() && !Number(a.cartaCorte) && (a.envioCarta3 != null)) {
          r[a.CodigoContrato] = r[a.CodigoContrato] || { CodigoContrato: a.CodigoContrato, denominacion: a.denominacion, IdCliente: a.IdCliente, Identificador: a.Identificador, impagados: [] };
          r[a.CodigoContrato].impagados.push(a);
        }
        return r;
      }, Object.create(null))

      const comprobarPago = this.impagados.reduce(function (r, a) {
        if (a.estadoPropio == 'Comprometido Pago' && Number(a.estadoPropioVencimiento) <= Date.now() || (a.FechaTransferencia != null && parseDate(a.FechaTransferencia, true) <= Date.now()) ) {
          r[a.Identificador] = r[a.Identificador] || { denominacion: a.denominacion, IdCliente: a.IdCliente, Identificador: a.Identificador, impagados: [] };
          r[a.Identificador].impagados.push(a);
        }
        return r;
      }, Object.create(null))

      const asignarProducto = this.impagados.reduce(function (r, a) {
        if (!a.productoImp) {
          r[a.CodigoContrato] = r[a.CodigoContrato] || { CodigoContrato: a.CodigoContrato, denominacion: a.denominacion, IdCliente: a.IdCliente, Identificador: a.Identificador, impagados: [] };
          r[a.CodigoContrato].impagados.push(a);
        }
        return r;
      }, Object.create(null))

      const enviarCorte = this.impagados.reduce(function (r, a) {
        if (a.cartaCorte && !a.amenazaCorteBool && a.situacionContrato == 'Activado' && !['Abonado', 'Pagado Tarjeta'].includes(a.estadoPropio)) {
          r[a.CodigoContrato] = r[a.CodigoContrato] || { CodigoContrato: a.CodigoContrato, denominacion: a.denominacion, IdCliente: a.IdCliente, Identificador: a.Identificador, impagados: [] };
          r[a.CodigoContrato].impagados.push(a);
        }
        return r;
      }, Object.create(null))

      const impagadosAbonados = this.impagados.reduce(function (r, a) {
        if (['Abonado', 'Pagado Tarjeta'].includes(a.estadoPropio)) {
          r[a.CodigoContrato] = r[a.CodigoContrato] || { CodigoContrato: a.CodigoContrato, denominacion: a.denominacion, IdCliente: a.IdCliente, Identificador: a.Identificador, impagados: [] };
          r[a.CodigoContrato].impagados.push(a);
        }
        return r;
      }, Object.create(null))

      const pendienteGiro = this.impagados.reduce(function (r, a) {
        if (a.estadoPropio == 'Pendiente Giro') {
          r[a.CodigoContrato] = r[a.CodigoContrato] || { CodigoContrato: a.CodigoContrato, denominacion: a.denominacion, IdCliente: a.IdCliente, Identificador: a.Identificador, impagados: [] };
          r[a.CodigoContrato].impagados.push(a);
        }
        return r;
      }, Object.create(null))

      // const eventosCalendario = (() => {
      //   let hoy = new Date();
      //   hoy.setHours(0, 0, 0, 0);
      //   hoy = hoy.getTime();
      //   const facturasFiltradas = this.facturas.filter(fac => parseDate(fac.FechaComienzoRemesa, true) >= hoy)
      //   return facturasFiltradas.map(fac => {
      //     return {
      //       start: parseDate(fac.FechaComienzoRemesa, true),
      //       name: [fac.SerieFactura, fac.NumeroFactura].join(' '),
      //       color: 'grey'
      //     }
      //   })
      // })()

      // let llamar = this.impagados.filter(imp =>
      //   imp.estadoPropio != 'Abonado' &&
      //   !Number(imp.dptoJuridico) &&
      //   (
      //     !imp.ultimoMensajeFecha ||
      //     (
      //       parseDate(imp.ultimoMensajeFecha, true) <= Date.now() - (1000 * 3600 * 24 * 5) &&
      //       parseDate(imp.ultimoMensajeFecha, true) >= parseDate(imp.FechaImpagado, true)
      //     )
      //   )
      // ).sort((a, b) => Number(b.ImporteTotal) - Number(a.ImporteTotal));

      // llamar = llamar.reduce(function (r, a) {
      //   r[a.Identificador] = r[a.Identificador] || { denominacion: a.denominacion, IdCliente: a.IdCliente, Identificador: a.Identificador, impagados: [] };
      //   r[a.Identificador].impagados.push(a);
      //   return r;
      // }, Object.create(null));


      return {
        fraccionamientos: Object.values(fraccionamientos),
        impagadosAbonados: Object.values(impagadosAbonados),
        primerAviso: Object.values(primerAviso),
        pendienteGiro: Object.values(pendienteGiro),
        segundoAviso: Object.values(segundoAviso),
        enviarCorte: Object.values(enviarCorte),
        asignarProducto: Object.values(asignarProducto),
        corteAviso: Object.values(corteAviso),
        comprobarPago: Object.values(comprobarPago),
        // llamar: Object.values(llamar),
        llamar: []
      }
    },
    idCabeceraAux() {
      return (this.impagados.find(x => x.IdCarteraCobro == this.detailsId) || {}).IdFacturaVentaCabecera
    },
  },
  mounted() {
    this.getAllData();
  },
  methods: {
    parseDate,
    timeAgo,
    getSituacionIcon,
    colorChip({ envioCarta1, envioCarta2, envioCarta3 }) {
      if (envioCarta3 <= Date.now()) return 'error';
      if (envioCarta2 <= Date.now()) return 'deep-orange';
      if (envioCarta1 <= Date.now()) return 'warning';
    },
    getImpagados() {
      return new Promise(async (resolve, reject) => {
        const { data: impagados } = await axios({ url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`, method: 'GET', params: { token: this.$store.getters.getJwtEmpresa, menuTab: 'actual' } });
        const { data: impagadosF } = await axios({ url: `${process.env.VUE_APP_OUR_API_URL}/impagados.php`, method: 'GET', params: { token: this.$store.getters.getJwtEmpresa, menuTab: 'fracTransferencia' } });
        this.porcentajes = {};
        this.impagados = parseImpagados([...impagados, ...impagadosF])
        resolve();
      })
    },
    async getFacturas() {
      return new Promise(async (resolve, reject) => {
        const { data: facturas } = await axios({ url: `${process.env.VUE_APP_OUR_API_URL}/facturacion.php`, method: 'GET', params: { token: this.$store.getters.getJwtEmpresa } });
        // console.log(facturas.map(({ IdFacturaVentaCabecera, IdRemesaCobro, IdRemesaImpagadoCobro }) => ({ IdFacturaVentaCabecera, IdRemesaCobro, IdRemesaImpagadoCobro })))
        this.facturas = facturas.map(f => {
          switch (Number(f.CodigoSituacionCobro)) {
            case -2: f.estado = "Gesico"; break;
            case -3: f.estado = "Pérdidas"; break;
            case 0: f.estado = 'Sin Especificar'; break;
            case 1: f.estado = 'Pendiente'; break;
            case 2: f.estado = 'Cobrado'; break;
            case 3: f.estado = 'Impagado'; break;
            case 4: f.estado = 'Remesado'; break;
            case 5: f.estado = 'Carta Aviso'; break;
            case 6: f.estado = 'Carta Corte'; break;
            case 7: f.estado = 'Cortado'; break;
            case 8: f.estado = 'Cobrado norma 57'; break;
            case 9: f.estado = 'Cancelado por abono'; break;
            case 10: f.estado = 'Carta Corte 1'; break;
            case 11: f.estado = 'Carta Deuda'; break;
            case 12: f.estado = 'Fraccionado'; break;
          }
          f.denominacion = f.RazonSocialTitular || [f.Apellido1Titular, f.Apellido2Titular, f.NombreTitular].filter((i) => !!i).join(" ");

          f.futuro = f.estado == 'Pendiente' || parseDate(f.FechaComienzoRemesa, true) >= (new Date().getTime());
          return f;
        });
        resolve()
      })
    },
    getAllData() {
      this.impagados = [];
      this.facturas = [];
      Promise.all([
        this.getImpagados(),
        this.getFacturas(),
      ]).finally(res => {
      })
    },
    verPDFfactura(idDocumento) {
      this.pdfId = idDocumento
      let str = this.$store.getters.getDatosEmpresa;
      const { IdUsuario } = str.iss;
      const IdEmpresa = this.$store.getters.getIdEmpresa;
      axios({
        method: "get",
        url: `${process.env.VUE_APP_API_URL}/FacturaVentaCabecera/FacturaPreviewPDF/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Documento/${idDocumento}`,
        responseType: "blob",
      })
        .then((res) => {
          let pdf = new File(
            [res.data],
            `factura_${new Date().getTime()}.pdf`,
            {
              type: "application/pdf",
            }
          );
          downloadFile(pdf, true);
          this.pdfId = null
        })
        .catch((err) => {
          this.$root.$emit("snack", "Ha ocurrido un error");
          this.pdfId = null
        });
    },
  },
  watch: {
    filter: {
      handler: function (v) {
        localStorage.setItem('filterTareas', JSON.stringify(v))
      },
      deep: true
    }
  }
}
</script>